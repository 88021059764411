import React from "react";
import {
      BannerContainer,
      BannerSection,
      BannerSliderContainer,
} from "../../home/styles";
import { Col, Container, Row } from "react-bootstrap";
import {
      AboutBottomContainer,
      AboutBottomContent,
      AboutContainer,
      AboutDescription,
      AboutSupplier,
      AboutSupplierContent,
      AboutSupplierDescription,
      AboutSupplierTitle,
      AboutTitle,
      NepalMap,
      TeamMemberContainer,
      TeamMemberContent,
      TeamMemberDirector,
      TeamMemberDirectorImage,
      TeamMembers,
      TeamMembersContent,
      TeamMembersImage,
} from "./styles";

const BannerBox = ({ loading, teams }) => {
      return (
            <>
                  <BannerSection>
                        <BannerContainer>
                              <BannerSliderContainer>
                                    <AboutContainer>
                                          <AboutTitle>
                                                [ About Yeti Paints ]
                                          </AboutTitle>
                                          <AboutDescription>
                                                <p>
                                                      Yeti Paints Nepal Pvt.Ltd
                                                      was established in the
                                                      year 1994( i.e. 2051
                                                      B.S.). The industry is
                                                      situated at HID, Hetauda,
                                                      the central part of Nepal.
                                                      It is connected with its
                                                      clientele and key
                                                      suppliers through road,
                                                      rail and air services.The
                                                      nearest airport Simara is
                                                      about 32 km and the
                                                      nearest railway station
                                                      Raxual (India) is about 60
                                                      km towards south.
                                                </p>
                                                <p>
                                                      The industry has a work
                                                      force of about 70 workmen,
                                                      which include 40 staffs
                                                      and 30 direct labors. The
                                                      work premise is built on a
                                                      land area 12.41 ropani
                                                      having two production
                                                      units in Hetauda
                                                      Industrial District. The
                                                      industry is equipped with
                                                      laboratory to carry out
                                                      necessary test for paint
                                                      manufacturing.
                                                </p>
                                                <p>
                                                      The annual production
                                                      capacity of the industry
                                                      is 9000 MT/KL of different
                                                      variet of paints and
                                                      paints related products.
                                                </p>
                                          </AboutDescription>
                                    </AboutContainer>
                              </BannerSliderContainer>
                        </BannerContainer>
                  </BannerSection>
                  <TeamMemberContainer>
                        <TeamMemberDirector className="px-0 ">
                              <Row className="g-0 p-0">
                                    <Col lg={6}>
                                          <TeamMemberContent>
                                                <h2 className="text-white mb-3">
                                                      Message from the Chairman
                                                </h2>
                                                <p className="mb-4">
                                                      We inspire and improve the
                                                      world by coloring and
                                                      protecting what matters.
                                                      Our paints, coatings and
                                                      innovative solutions make
                                                      the places and spaces in
                                                      our world brighter and
                                                      stronger. Your skills,
                                                      talent and passion make it
                                                      possible to live our
                                                      purpose, and for our
                                                      customers and business to
                                                      achieve great results.
                                                </p>

                                                <h3>Mr. Jhalak Bahadur KC</h3>
                                                <h4>
                                                      {" "}
                                                      Chairman of Yeti Paints
                                                </h4>
                                          </TeamMemberContent>
                                    </Col>
                                    <Col lg={6}>
                                          <TeamMemberDirectorImage>
                                                <img
                                                      src="/images/img/team/manufacturing-employee-shop-floor.jpeg"
                                                      alt="Chairman of Yeti Paints"
                                                      className="img-fluid"
                                                      height="100%"
                                                      width="100%"
                                                />
                                          </TeamMemberDirectorImage>
                                    </Col>
                              </Row>
                        </TeamMemberDirector>
                  </TeamMemberContainer>
                  <TeamMembers className="mb-5">
                        {!loading
                              ? teams?.length > 0 && (
                                      <>
                                            <h3>[ Our Teams ]</h3>
                                            <Row className="g-5">
                                                  {teams?.map((team, index) => (
                                                        <Col lg={3}>
                                                              <TeamMembersContent>
                                                                    <TeamMembersImage>
                                                                          <img
                                                                                src={
                                                                                      team.image
                                                                                }
                                                                                alt={`Yeti Paints Nepal Team: ${team.name}`}
                                                                                className="img-fluid border w-100"
                                                                                height="100%"
                                                                                width="100%"
                                                                          />
                                                                    </TeamMembersImage>
                                                                    <h3 className="mt-3">
                                                                          {
                                                                                team.name
                                                                          }
                                                                    </h3>
                                                                    <p>
                                                                          {
                                                                                team.position
                                                                          }
                                                                    </p>
                                                              </TeamMembersContent>
                                                        </Col>
                                                  ))}
                                            </Row>
                                      </>
                                )
                              : null}
                  </TeamMembers>

                  <AboutSupplier>
                        <Container>
                              <Row className="g-5">
                                    <Col lg={6}>
                                          <NepalMap>
                                                <img
                                                      src="/images/nepal.png"
                                                      alt="Yeti Paints Nepal Pvt. Ltd."
                                                      className="img-fluid"
                                                      height="100%"
                                                      width="100%"
                                                />
                                          </NepalMap>
                                    </Col>
                                    <Col lg={6}>
                                          <AboutSupplierContent>
                                                <AboutSupplierTitle>
                                                      Our Presence
                                                </AboutSupplierTitle>
                                                <AboutSupplierDescription>
                                                      The products are
                                                      distributed through its
                                                      own depots based in
                                                      Kathmandu, Pokhara,
                                                      Butwal, Biratnagar and
                                                      Hetauda, which supplies to
                                                      a wide network of dealers
                                                      spread all over the
                                                      country.
                                                </AboutSupplierDescription>
                                          </AboutSupplierContent>
                                    </Col>
                              </Row>
                        </Container>
                  </AboutSupplier>
                  <AboutBottomContainer>
                        <Container>
                              <Row>
                                    <Col lg={6}>
                                          <AboutBottomContent className="mt-5">
                                                <h3>Management Team</h3>
                                                <p>
                                                      Above 30 years of
                                                      experience in paints
                                                      industries personnel are
                                                      involved in our management
                                                      team. Beside that Mr.
                                                      Jhalak Bahadur KC,
                                                      Chairman is himself a
                                                      technical person. So that
                                                      his direct involvement in
                                                      quality control of the
                                                      paints confirms production
                                                      of best quality paints.
                                                </p>
                                          </AboutBottomContent>
                                          <AboutBottomContent>
                                                <h3>Quality Standards</h3>
                                                <p>
                                                      All the products meet the
                                                      standard quality
                                                      parameters of Nepal Bureau
                                                      of Standards.
                                                </p>
                                          </AboutBottomContent>
                                    </Col>
                                    <Col lg={6}>
                                          <img
                                                src="/images/standard.png"
                                                alt="Yeti Paints Nepal Pvt Ltd."
                                                className="img-fluid"
                                                height="100%"
                                                width="100%"
                                          />
                                    </Col>
                              </Row>
                        </Container>
                  </AboutBottomContainer>
            </>
      );
};

export default BannerBox;
