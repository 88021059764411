export const distributions = [
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery5/photo_2025-02-28_11-26-44.jpg'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery5/photo_2025-02-28_11-26-41.jpg'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery5/photo_2025-02-28_11-27-04.png'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery5/photo_2025-02-28_11-26-47.jpg'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery4/photo_2025-02-04_13-34-23-2.jpg'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery4/photo_2025-02-04_13-34-23-3.jpg'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery4/photo_2025-02-04_12-00-07-3.jpg'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery4/photo_2025-02-04_12-00-07-4.jpg'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery4/photo_2025-02-04_12-00-07.jpg'
      },
      {
            title: 'Dealers Gift Distribution Memories - 2025',
            image: '/images/gallery4/photo_2025-02-04_13-34-23.jpg'
      }
];

export const goaTours = [
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-17.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-20.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-22.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-24.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-27.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-30.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-31.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-33.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-35.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-38.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-39.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-41.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-42.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-43.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-25.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-28.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-34.jpg'
      },
      {
            title: 'Goa Tour 2024',
            image: '/images/gallery3/photo_2024-05-13_10-58-37.jpg'
      }
];

export const giftDistribution = [
      {
            title: 'Gift Distribution 2024',
            image: '/images/gallery2/photo_2024-05-13_10-54-27.jpg'
      },
      {
            title: 'Gift Distribution 2024',
            image: '/images/gallery2/photo_2024-05-13_10-54-25.jpg'
      },
      {
            title: 'Gift Distribution 2024',
            image: '/images/gallery2/photo_2024-05-13_10-54-25.jpg'
      },
      {
            title: 'Gift Distribution 2024',
            image: '/images/gallery2/photo_2024-05-13_10-54-19.jpg'
      },
      {
            title: 'Gift Distribution 2024',
            image: '/images/gallery2/photo_2024-05-13_10-54-15.jpg'
      }
];
