import React, { useRef, useEffect } from 'react';

import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { FancyBoxContainer } from './styles';

function Fancybox(props) {
      const containerRef = useRef(null);

      useEffect(() => {
            const container = containerRef.current;

            NativeFancybox.bind(`[data-fancybox="${props.title}"]`, {
                  dragToClose: false,

                  Toolbar: {
                        display: {
                              left: ['close'],
                              middle: [],
                              right: []
                        }
                  },

                  Images: {
                        zoom: false
                  },

                  Thumbs: {
                        type: 'classic'
                  },

                  Carousel: {
                        transition: false,
                        friction: 0
                  },

                  on: {
                        'Carousel.ready Carousel.change': (fancybox) => {
                              fancybox.container.style.setProperty(
                                    '--bg-image',
                                    `url("${fancybox.getSlide().thumbSrc}")`
                              );
                        }
                  }
            });

            return () => {
                  NativeFancybox.unbind(container);
                  NativeFancybox.close();
            };
      });

      return (
            <FancyBoxContainer ref={containerRef}>
                  {props.children}
            </FancyBoxContainer>
      );
}

export default Fancybox;
